import React, { useEffect, useState } from "react";
import NuevoServicioDrawer from "../blockComponents/drawers/NuevoServicioDrawer";
import {
  FaChevronLeft,
  FaChevronRight,
  FaCogs,
  FaCubes,
  FaEye,
  FaFilter,
  FaPlus,
  FaSearch,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { BACKEND } from "../config/configEnvs";
import EditarServicioDrawer from "../blockComponents/drawers/EditarServicioDrawer";
import FiltradoRapidoServiciosDialog from "../blockComponents/dialogs/FiltradoRapidoServiciosDialog";
const Servicios = () => {
  const [planesList, setPlanesList] = useState([]);
  const [trabajadoresList, setTrabajadoresList] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("")

  const [serviciosList, setServiciosList] = useState([]);
  const [openServicio, setOpenServicio] = useState(false);
  const [openEditarServicio, setOpenEditarServicio] = useState({
    open: false,
    data: null,
  });

  const [serviciosMetaData, setServiciosMetaData] = useState({
    totalPages: 0,
    page: 0,
    hasPrevPage: false,
    hasNextPage: false,
    totalDocs: 0,
  });

  const [limit, setLimit] = useState(10)

  const [openedFiltradoRapido, setOpenedFiltradoRapido] = useState(false);

  const getPlanes = async () => {
    try {
      const responsePlanes = await axios.get(`${BACKEND}/api/planes`);
      setPlanesList(responsePlanes.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const getServiciosList = async () => {
    try {
      let url = `${BACKEND}/api/servicios?limit=${limit}&page=${page}`

      if (searchText) {
        url += `&where[name][like]=${encodeURIComponent(searchText)}`
      }

      console.log(url)
      const responsePlanes = await axios.get(url);

      console.log(responsePlanes)
      setServiciosList(responsePlanes.data.docs);

      setServiciosMetaData({
        totalPages: responsePlanes.data.totalPages,
        page: responsePlanes.data.page,
        hasNextPage: responsePlanes.data.hasNextPage,
        hasPrevPage: responsePlanes.data.hasPrevPage,
        totalDocs: responsePlanes.data.totalDocs,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchServices = () => { // Funcion para buscar clientes, automáticamente lleva a la página 1
    getServiciosList()
    setPage(1)
  }
  
  const handleResetSearch = () => { // Resetea la búsqueda
    setSearchText(""); // Cambiar el estado
  };

  useEffect(() => { // Solo se ejecuta cuando se vacea la barra de búsqueda
    if (searchText === "") {
      getServiciosList(); // Solo se ejecuta cuando se reinicia la búsqueda
      setPage(1); // Reiniciar la paginación si es necesario
    }
  }, [searchText]);

  const getTrabajadoresList = async () => {
    try {
      const responseTrabajadores = await axios.get(
        `${BACKEND}/api/trabajadores?limit=100`
      );
      setTrabajadoresList(responseTrabajadores.data.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const pageRange = [];
  const startPage = Math.max(1, page - 2);
  const endPage = Math.min(
    page + 2,
    serviciosMetaData.totalPages ? serviciosMetaData.totalPages : 5
  );
  for (let i = startPage; i <= endPage; i++) {
    pageRange.push(i);
  }
  useEffect(() => {
    getServiciosList();
  }, [page, limit]);

  useEffect(() => {
    getPlanes();
    getTrabajadoresList();
  }, []);
  const eliminarServicio = async (idServicio) => {
    if (!window.confirm("Eliminar")) {
      return;
    }
    try {
      await axios
        .delete(`${BACKEND}/api/servicios/${idServicio}`)
        .then(() => getServiciosList())
        .then(() => toast.success("Eliminado", { position: "top-center" }));
    } catch (error) {
      console.log(error);
      toast.error("Error al eliminar");
    }
  };

  return (
    <div>
      <NuevoServicioDrawer
        open={openServicio}
        planesList={planesList}
        trabajadoresList={trabajadoresList}
        refetch={() => getServiciosList()}
        onClose={() => setOpenServicio(false)}
        title={"Nuevo Servicio"}
      />
      <FiltradoRapidoServiciosDialog
        open={openedFiltradoRapido}
        onClose={() => setOpenedFiltradoRapido(false)}
      />
      <EditarServicioDrawer
        open={openEditarServicio.open}
        data={openEditarServicio.data}
        trabajadoresList={trabajadoresList}
        onClose={() =>
          setOpenEditarServicio((prev) => ({ ...prev, open: false }))
        }
        refetch={() => getServiciosList()}
        planesList={planesList}
        title={"Editar Servicio"}
      />
      
        <div className="flex flex-row items-center justify-between px-8 py-2 bg-zinc-800">

          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="flex items-center gap-4 p-4">
                <FaCubes className="text-white text-2xl" />
                <h2 className="text-2xl font-bold text-white">Servicios</h2>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center gap-4">

            <div className="">
              <button
                type="button"
                onClick={() => setOpenedFiltradoRapido(true)}
                className="flex justify-center items-center px-6 py-2 gap-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
              >
                <FaFilter />
                Filtrados rapidos
              </button>
            </div>
            
            <div className="flex items-center gap-4">
              <label htmlFor="limit" className="text-white">Límite</label>
              <select
                onChange={(e) => setLimit(e.target.value)}
                className="bg-white border border-gray-300 rounded-md shadow-sm px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                >
                  <option key={1} value={10}>10</option>
                  <option key={2} value={20}>20</option>
                  <option key={3} value={30}>30</option>
                  <option key={4} value={40}>40</option>
                  <option key={5} value={50}>50</option>
              </select>
            </div>

            <div className="flex flex-row justify-center items-center gap-4">
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="Buscar servicio"
                  className="w-full text-gray-700 bg-white border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent py-[6px] pr-10"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText && (
                  <FaTimes
                    onClick={() => handleResetSearch()}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 cursor-pointer"
                  />
                )}
              </div>

              <button 
                className="inline-flex items-center px-6 py-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
                onClick={() => handleSearchServices()}
              >
                <FaSearch className="mr-2" />
                Buscar
              </button>
            </div>
            
            <div className="">
              <button
                type="button"
                onClick={() => setOpenServicio(true)}
                className="flex justify-center items-center px-6 py-2 gap-2 text-sm font-semibold text-white bg-orange-500 rounded-md shadow-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 transition-colors duration-300"
              >
                <FaPlus />
                Agregar nuevo servicio
              </button>
            </div>

          </div>
        </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Servicio
              </th>
              <th scope="col" className="px-6 py-3">
                Encargado
              </th>
              <th scope="col" className="px-6 py-3">
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
            {serviciosList.map((e) => (
              <tr key={e.id} className="odd:bg-white  even:bg-gray-50 ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  {e.name}
                </th>
                <td className="px-6 py-4">
                  <p className=" ">
                    {" "}
                    {e?.encargado?.name} {e?.encargado ? " - " : "Sin asignar"}{" "}
                    {e?.encargado?.puesto}{" "}
                  </p>
                </td>
                <td className="px-6 py-4">
                  <div className=" flex justify-start gap-2 items-center">
                    <button
                      type="button"
                      onClick={() =>
                        setOpenEditarServicio({ open: true, data: e })
                      }
                      className=" text-sm font-semibold flex justify-center gap-2 border hover:bg-orange-100 border-orange-100 items-center bg-orange-50 p-2 rounded-md text-orange-600"
                    >
                      Detalles <FaEye />
                    </button>
                    <button
                      type="button"
                      onClick={() => eliminarServicio(e.id)}
                      className=" text-sm font-semibold flex justify-center gap-2 border hover:bg-orange-100 border-orange-100 items-center bg-orange-50 p-2 rounded-md text-orange-600"
                    >
                      Eliminar <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {serviciosList && serviciosList?.length > 0 && (
        <div className="flex flex-col items-center py-10">
          <span className="text-sm text-gray-700 dark:text-gray-500 ">
            Mostrando
            <span className="font-semibold text-gray-900 dark:text-gray-300 ">
              {" "}
              {serviciosList.length}
            </span>{" "}
            de
            <span className="font-semibold text-gray-900 dark:text-gray-300 ">
              {" "}
              {serviciosMetaData.totalDocs}
            </span>{" "}
            servicios
          </span>

          <nav aria-label="Page navigation example">
            <ul className="flex items-center -space-x-px h-10 text-base">
              <li>
                <button
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                  className="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 "
                >
                  <span className="sr-only">Previous</span>
                  <FaChevronLeft className=" w-3 h-3 text-gray-700 dark:text-gray-400" />
                </button>
              </li>

              {pageRange.map((pageNumber) => (
                <li key={pageNumber}>
                  <button
                    onClick={() => setPage(pageNumber)}
                    className={`flex items-center justify-center px-4 h-10 leading-tight ${
                      page === pageNumber
                        ? "text-white  border-blue-300 bg-blue-600 hover:bg-blue-700  "
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                    }`}
                  >
                    {pageNumber}
                  </button>
                </li>
              ))}

              <li>
                <button
                  onClick={() => setPage(page + 1)}
                  disabled={!serviciosMetaData.hasNextPage}
                  className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 "
                >
                  <span className="sr-only">Next</span>
                  <FaChevronRight className=" w-3 h-3 text-gray-700 dark:text-gray-400" />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Servicios;
