import React, { useEffect, useState } from "react";
import InputText from "../ui/InputText";
import SelectInput from "../ui/SelectInput";
import axios from "axios";
import { FaCloudUploadAlt, FaPlus, FaTrash } from "react-icons/fa";
import { BACKEND } from "../../config/configEnvs";
import { toast } from "react-toastify";
import DrawerRight from "../ui/DrawerRight";
import FileUploadFilePond from "../ui/FileUploadFilePond";

const EditarServicioDrawer = ({ open, onClose, title, planesList, refetch, data, trabajadoresList }) => {
  
  const [fileToUpload, setFileToUpload] = useState(null)
  
  const [formValues, setFormValues] = useState({
    name: "",
    descripcion: "",
    reviewVideo: "",
    proyectVideo: "",
    makingOffVideo: "",
    processCron: "",
    successCaseVideo: "",
    linkCanvas: "",
    pdf: "",
    encargado: null,
    subarea: "",
    cuentaComercialPublicitariaActive: false,
    monstrarRedesClientes: true,
    template: "",
    tipoPago: "",
    categoria: ""
  });

  const [templates, setTemplates] = useState([])

  const [preciosUnitarios, setPreciosunitarios] = useState([]);
  const [preciounitario, setPrecioUnitario] = useState({
    plan: "",
    precio: 0,
    puntos: 0,
    descripcion: "",
    servicioProcesoClienteMediafiles: [],
    servicioProcesoClienteMediaId: "",
    servicioProcesoClienteMediaInitialURL: "",

    servicioProcesoEspecialistaMediaFiles: [],
    servicioProcesoEspecialistaMediaId: "",
    servicioProcesoEspecialistaMediaInitialURL: "",
    linkReferencia: "",
  });
  const [itemDescripciones, setitemDescripciones] = useState([]);
  const [itemDescripcion, setItemDescripcion] = useState({ item: "" });

  const handleAddPrecioUnitario = () => {
    setPreciosunitarios([...preciosUnitarios, preciounitario]);
    setPrecioUnitario({
      plan: "",
      precio: 0,
      puntos: 0,
      descripcion: "",
      servicioProcesoClienteMediafiles: [],
      servicioProcesoClienteMediaId: "",
      servicioProcesoClienteMediaInitialURL: "",

      servicioProcesoEspecialistaMediaFiles: [],
      servicioProcesoEspecialistaMediaId: "",
      servicioProcesoEspecialistaMediaInitialURL: "",
      linkReferencia: "",
    });
  };

  const handleDeletePreciounitario = (index) => {
    const updatedPairs = [...preciosUnitarios];
    updatedPairs.splice(index, 1);
    setPreciosunitarios(updatedPairs);
  };

  const handleInputChangePreciosUnitarios = (index, key, value) => {
    const updatedPairs = [...preciosUnitarios];
    updatedPairs[index][key] = value;
    setPreciosunitarios(updatedPairs);
  };
  
  const handlePrepareFile = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setFileToUpload(file);
    } else {
      alert("Solo se aceptan archivos PDF.");
      e.target.value = null; // Limpia el input si no es un PDF válido
    }
  };

  const handleUploadFile = async (file) => { // Subir la imagen
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `${BACKEND}/api/serviciosPdfs`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const mediaId = response.data.doc.id; 
      return mediaId;
    } catch (error) {
      console.log(error);
      toast.error("Error subiendo la imagen: " + error)
    }
  };

  const actualizarServicio = async () => {

    if (preciosUnitarios.some((e) => e.plan === "")) {
      toast.warning("Completa los planes o sus campos en blanco");
      return;
    }
    if (formValues.name === "") {
      toast.warning("Completa el nombre y descripción");
      return;
    }
    if (
      !formValues.encargado ||
      formValues.encargado === "Selecciona una opción"
    ) {
      toast.warning("Selecciona un encargado predeterminado");
      return;
    }
    try {

      let pdfId = ""

      if (fileToUpload) {

        if (formValues.pdf !== "") {
          try {
            await axios.delete(`${BACKEND}/api/serviciosPdfs/${formValues.pdf.id}`);
          } catch (error) {
            toast.error("No se pudo eliminar la imagen anterior.");
            return;
          }
        }

        if (fileToUpload !== null) {
          pdfId = await handleUploadFile(fileToUpload)
        } else {
          pdfId = formValues.pdf
        }
      }
      
      await axios.put(`${BACKEND}/api/servicios/${data?.id}`, {
        name: formValues.name,
        descripcion: formValues.descripcion,
        preciosUnitarios: preciosUnitarios.map((e) => ({
          plan: e.plan,
          precio: e.precio,
          puntos: e.puntos,
          descripcion: e.descripcion,
          servicioProcesoClienteMedia: e?.servicioProcesoClienteMediaId
            ? e.servicioProcesoClienteMediaId
            : null,
          servicioProcesoEspecialistaMedia:
            e?.servicioProcesoEspecialistaMediaId
              ? e.servicioProcesoEspecialistaMediaId
              : null,
          linkReferencia: e.linkReferencia,
        })),
        items: itemDescripciones,
        encargado: formValues.encargado,
        subarea: formValues.subarea,
        reviewVideo: formValues.reviewVideo,
        proyectVideo: formValues.proyectVideo,
        makingOffVideo: formValues.makingOffVideo,
        successCaseVideo: formValues.successCaseVideo,
        processCron: formValues.processCron,
        pdf: pdfId,
        cuentaComercialPublicitariaActive: formValues.cuentaComercialPublicitariaActive,
        monstrarRedesClientes: formValues.monstrarRedesClientes,
        template: formValues.template,
        tipoPago: ( formValues.tipoPago !== "" ? formValues.tipoPago : null ),
        categoria: ( formValues.categoria !== "" ? formValues.categoria : null )
      });

      setitemDescripciones([]);
      setPreciosunitarios([]);
      onClose();
      toast.success("Actualizado", { position: "top-center" });
      refetch();
    } catch (error) {
      console.log(error)
      toast.error("Error al actualizar el servicio");
    }
  };

  const getTemplates = async () => {
    try {
      const response = await axios.get(`${BACKEND}/api/fases?limit=0`)
      const templatesFromDB = response.data.docs.map(item => item.defaultService)
      setTemplates([...new Set(templatesFromDB)])
    } catch (error) {
      toast.error("Error obteniendo las plantillas: " + error)
    }
  }

  // useEffect(() => {
  //   if (data?.name) setFormValues((prev) => ({ ...prev, name: data?.name }));

  //   if (data?.subarea)
  //     setFormValues((prev) => ({ ...prev, subarea: data?.subarea }));

  //   if (data?.descripcion)
  //     setFormValues((prev) => ({ ...prev, descripcion: data?.descripcion }));

  //   if (data?.cuentaComercialPublicitariaActive) {
  //     setFormValues((prev) => ({
  //       ...prev,
  //       cuentaComercialPublicitariaActive: true,
  //     }));
  //   } else {
  //     setFormValues((prev) => ({
  //       ...prev,
  //       cuentaComercialPublicitariaActive: false,
  //     }));
  //   }

  //   if (data?.monstrarRedesClientes) {
  //     setFormValues((prev) => ({
  //       ...prev,
  //       monstrarRedesClientes: true,
  //     }));
  //   } else {
  //     setFormValues((prev) => ({
  //       ...prev,
  //       monstrarRedesClientes: false,
  //     }));
  //   }

  //   if (data?.encargado) {
  //     setFormValues((prev) => ({ ...prev, encargado: data?.encargado.id }));
  //   } else {
  //     setFormValues((prev) => ({ ...prev, encargado: null }));
  //   }

  //   if (data?.preciosUnitarios && data?.preciosUnitarios?.length > 0)
  //     setPreciosunitarios(
  //       data.preciosUnitarios.map((e) => ({
  //         plan: e?.plan.id,
  //         precio: e.precio,
  //         puntos: e.puntos,
  //         descripcion: e?.descripcion,
  //         servicioProcesoClienteMediafiles: [],
  //         servicioProcesoClienteMediaId: e?.servicioProcesoClienteMedia?.id
  //           ? e.servicioProcesoClienteMedia.id
  //           : "",
  //         servicioProcesoClienteMediaInitialURL: e?.servicioProcesoClienteMedia
  //           ?.url
  //           ? e.servicioProcesoClienteMedia.url
  //           : "",

  //         servicioProcesoEspecialistaMediaFiles: [],
  //         servicioProcesoEspecialistaMediaId: e
  //           ?.servicioProcesoEspecialistaMedia?.id
  //           ? e?.servicioProcesoEspecialistaMedia.id
  //           : "",
  //         servicioProcesoEspecialistaMediaInitialURL: e
  //           ?.servicioProcesoEspecialistaMedia?.url
  //           ? e?.servicioProcesoEspecialistaMedia.url
  //           : "",
  //           linkReferencia:e?.linkReferencia ? e?.linkReferencia: ""
  //       }))
  //     );

  //   if (data?.items && data?.items.length > 0)
  //     setitemDescripciones(data.items.map((e) => ({ item: e.item })));

  //   if (data?.template) {
  //     setFormValues((prev) => ({ ...prev, template: data?.template}))

  //   if (data?.tipoPago) {
  //     setFormValues((prev) => ({ ...prev, tipoPago: data?.tipoPago}))
  //   }

  //   if (data?.categoria) {
  //     setFormValues((prev) => ({ ...prev, categoria: data?.categoria}))
  //   }


  //   }
  // }, [data]);

  useEffect(() => {
    if (data) {
      const newFormValues = {
        name: data.name || "",
        subarea: data.subarea || "",
        descripcion: data.descripcion || "",

        reviewVideo: data.reviewVideo || "",
        proyectVideo: data.proyectVideo || "",
        makingOffVideo: data.makingOffVideo || "",
        processCron: data.processCron || "",
        successCaseVideo: data.successCaseVideo || "",

        pdf: data.pdf || "",

        cuentaComercialPublicitariaActive: !!data.cuentaComercialPublicitariaActive,
        monstrarRedesClientes: !!data.monstrarRedesClientes,
        encargado: data.encargado?.id || null,
        template: data.template || "",
        tipoPago: data.tipoPago || "",
        categoria: data.categoria || "",
      };
  
      setFormValues((prev) => ({ ...prev, ...newFormValues }));
  
      if (data.preciosUnitarios && data.preciosUnitarios.length > 0) {
        setPreciosunitarios(
          data.preciosUnitarios.map((e) => ({
            plan: e?.plan.id,
            precio: e.precio,
            puntos: e.puntos,
            descripcion: e.descripcion || "",
            servicioProcesoClienteMediafiles: [],
            servicioProcesoClienteMediaId: e.servicioProcesoClienteMedia?.id || "",
            servicioProcesoClienteMediaInitialURL: e.servicioProcesoClienteMedia?.url || "",
            servicioProcesoEspecialistaMediaFiles: [],
            servicioProcesoEspecialistaMediaId: e.servicioProcesoEspecialistaMedia?.id || "",
            servicioProcesoEspecialistaMediaInitialURL: e.servicioProcesoEspecialistaMedia?.url || "",
            linkReferencia: e.linkReferencia || ""
          }))
        );
      }
  
      if (data.items && data.items.length > 0) {
        setitemDescripciones(data.items.map((e) => ({ item: e.item })));
      }
    }
  }, [data]);
  

  useEffect(() => {
    getTemplates()
  }, [])

  if (!data) return;

  return (
    <DrawerRight 
      open={open} 
      onClose={() => {
        onClose()
        setFileToUpload(null)}} 
      maxWidth={700} 
      title={title}
    >

      <InputText
        value={formValues.name}
        title={"Nombre"}
        setValue={(e) => setFormValues((prev) => ({ ...prev, name: e }))}
      />

      <SelectInput
        title={"Subarea"}
        value={formValues.subarea}
        setValue={(e) => setFormValues((prev) => ({ ...prev, subarea: e }))}
        options={[
          { id: 1, value: "Campañas", label: "Campañas" },
          { id: 2, value: "Social Media", label: "Social Media" },
          { id: 3, value: "CMS", label: "CMS" },
          { id: 4, value: "Code", label: "Code" },
          { id: 5, value: "Audiovisuales", label: "Audiovisuales" },
        ]}
      />

      <SelectInput
        title={"Encargado predeterminado"}
        value={formValues.encargado}
        setValue={(e) => setFormValues((prev) => ({ ...prev, encargado: e }))}
        options={trabajadoresList.map((e) => ({
          id: e.id,
          value: e.id,
          label: e.name + " - " + e?.puesto,
        }))}
      />

      <div className="my-3">
        <label
          htmlFor={"Plantilla"}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {"Plantilla de proceso"}
        </label>
        <select
          id={"Plantilla"}
          required={false}
          //disabled={disabled}
          value={formValues.template}
          onChange={(e) => setFormValues((prev) => ({...prev, template: e.target.value}))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        >
            <option selected value="">Selecciona una opción</option>
            {templates.map((item) => (
              <option key={item.index} value={item}>{item}</option>
            ))}
        </select>
      </div>

      <div className="my-3">
        <label
          htmlFor={"tipoPago"}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {"Tipo de pago"}
        </label>
        <select
          id={"tipoPago"}
          required={false}
          //disabled={disabled}
          value={formValues.tipoPago}
          onChange={(e) => setFormValues((prev) => ({...prev, tipoPago: e.target.value}))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        >
            <option value="">Selecciona una opción</option>
            <option value="Unico">Unico</option>
            <option value="Mensual">Mensual</option>
            <option value="Mixto">Mixto</option>
            <option value="Anual">Anual</option>

        </select>
      </div>

      <div className="my-3">
        <label
          htmlFor={"categoria"}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {"Categoría"}
        </label>
        <select
          id={"categoria"}
          required={false}
          //disabled={disabled}
          value={formValues.categoria}
          onChange={(e) => setFormValues((prev) => ({...prev, categoria: e.target.value}))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        >

          <option value="Web">Web</option>
          <option value="Soporte web">Soporte web</option>
          <option value="SEO y UI/UX">SEO y UI/UX</option>
          <option value="BOT">BOT</option>
          <option value="Hosting y dominio">Hosting y dominio</option>
          <option value="Otros web">Otros web</option>
          <option value="Audiovisual">Audiovisual</option>
          <option value="SMM">SMM</option>
          <option value="ADS">ADS</option>
          <option value="Asesoria">Asesoria</option>

        </select>
      </div>

      <div className="my-3">
        <label
          htmlFor={"categoria"}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {"Review video"}
        </label>
        <input
          id={"reviewVideo"}
          required={false}
          placeholder="www.youtube.com"
          value={formValues.reviewVideo}
          onChange={(e) => setFormValues((prev) => ({...prev, reviewVideo: e.target.value}))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        />
      </div>

      <div className="my-3">
        <label
          htmlFor={"categoria"}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {"Proyecto video"}
        </label>
        <input
          id={"proyectVideo"}
          required={false}
          placeholder="www.youtube.com"
          value={formValues.proyectVideo}
          onChange={(e) => setFormValues((prev) => ({...prev, proyectVideo: e.target.value}))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        />
      </div>

      <div className="my-3">
        <label
          htmlFor={"categoria"}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {"Making off video"}
        </label>
        <input
          id={"makingOffVideo"}
          required={false}
          placeholder="www.youtube.com"
          value={formValues.makingOffVideo}
          onChange={(e) => setFormValues((prev) => ({...prev, makingOffVideo: e.target.value}))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        />
      </div>

      <div className="my-3">
        <label
          htmlFor={"categoria"}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {"Casos de éxito"}
        </label>
        <input
          id={"processCron"}
          required={false}
          placeholder="www.youtube.com"
          value={formValues.successCaseVideo}
          onChange={(e) => setFormValues((prev) => ({...prev, successCaseVideo: e.target.value}))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        />
      </div>


      <div className="my-3">
        <label
          htmlFor={"categoria"}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {"Proceso y cronograma"}
        </label>
        <input
          id={"processCron"}
          required={false}
          placeholder="www.canva.com"
          value={formValues.processCron}
          onChange={(e) => setFormValues((prev) => ({...prev, processCron: e.target.value}))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        />
      </div>

      {/* <div className="my-3">
        <label
          htmlFor={"categoria"}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {"Canvas"}
        </label>
        <input
          id={"linkCanvas"}
          required={false}
          value={formValues.linkCanvas}
          onChange={(e) => setFormValues((prev) => ({...prev, linkCanvas: e.target.value}))}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        />
      </div> */}

      {/* <div className="image">
      <label
          htmlFor={"pdf"}
          className="block mb-2 text-sm font-medium text-gray-900 "
        >
          {"PDF"}
        </label>
        <div className="flex">
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-48 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 transition-all duration-100"
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              {fileToUpload === null ? (
                formValues.pdf !== "" ? (
                // Mostrar enlace al PDF existente si ya hay uno cargado
                  <>
                    <p className="text-sm text-gray-500 mb-2">
                      Archivo actual:
                      <a
                        href={formValues.pdf.url} // Enlace al archivo PDF
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 text-blue-600 font-semibold underline text-xs"
                      >
                        Ver archivo
                      </a>
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      Puedes subir un nuevo archivo para reemplazarlo.
                    </p>
                  </>
                ) : (
                  <>
                    <FaCloudUploadAlt className="text-2xl text-gray-500 mb-2" />
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="font-semibold text-xs">Sube un archivo</span>
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      PDF - Máximo 5MB
                    </p>
                  </>
                )
              ) : (
                <>
                  <div className="flex flex-col items-center justify-center gap-2 h-16 text-gray-700">
                    <p className="font-semibold">{fileToUpload.name}</p>
                  </div>
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold text-xs">1 archivo listo para subir</span>
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    PDF - Máximo 5MB
                  </p>
                </>
              )}
            </div>
            <input
              id="dropzone-file"
              type="file"
              accept="application/pdf"
              onChange={handlePrepareFile}
              className="hidden"
            />
          </label>
        </div>
      </div> */}

      <div className=" pt-4">
        <div>
          <span className="block mb-2 text-sm font-medium text-gray-900 ">
            {" "}
            ¿ Habilitar cuenta publicitaria | cuenta agencia ?
          </span>
        </div>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            id={"cuentaComercialPublicitariaActive"}
            type="checkbox"
            value={formValues.cuentaComercialPublicitariaActive}
            checked={formValues.cuentaComercialPublicitariaActive}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                cuentaComercialPublicitariaActive:
                  !formValues.cuentaComercialPublicitariaActive,
              }))
            }
            className="sr-only peer"
          />{" "}
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all  peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 ">
            {formValues.cuentaComercialPublicitariaActive
              ? "Habilitado"
              : "Deshabilitado"}
          </span>
        </label>
      </div>

      <div className=" pt-4">
        <div>
          <span className="block mb-2 text-sm font-medium text-gray-900 ">
            {" "}
            ¿ Mostrar en redes clientes ?
          </span>
        </div>
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            id={"cuentaComercialPublicitariaActive"}
            type="checkbox"
            value={formValues.monstrarRedesClientes}
            checked={formValues.monstrarRedesClientes}
            onChange={(e) =>
              setFormValues((prev) => ({
                ...prev,
                monstrarRedesClientes: !formValues.monstrarRedesClientes,
              }))
            }
            className="sr-only peer"
          />{" "}
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all  peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 ">
            {formValues.monstrarRedesClientes ? "Habilitado" : "Deshabilitado"}
          </span>
        </label>
      </div>

      <div className=" mt-8 h-[1px] w-full bg-gray-200"></div>

      <div className=" mt-6">
        <div className=" flex justify-center ">
          <button
            type="button"
            className=" pb-1 text-sm dark:hover:text-blue-500 dark:hover:border-blue-500 dark:border-b  text-gray-700 hover:text-blue-600 hover:border-blue-600 hover:border-b  dark:text-blue-100  flex justify-start gap-2 items-center "
            onClick={handleAddPrecioUnitario}
          >
            Agregar Planes
            <FaPlus />
          </button>
        </div>

        <div className=" flex flex-col gap-4 pt-4">
          {preciosUnitarios.map((preciounitario, index) => (
            <div
              className="flex flex-col  gap-2 w-full shadow-md bg-gray-100  rounded-md p-2"
              key={index + preciounitario.plan.id}
            >
              <div>
                <SelectInput
                  title={"Plan"}
                  setValue={(e) =>
                    handleInputChangePreciosUnitarios(index, "plan", e)
                  }
                  value={preciounitario.plan}
                  options={planesList.map((e) => ({
                    id: e.id,
                    value: e.id,
                    label: e.name,
                  }))}
                />
              </div>
              <div className=" grid grid-cols-2 gap-2">
                <div>
                  <label
                    htmlFor={"precio"}
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Precio
                  </label>

                  <input
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Precio"
                    value={preciounitario.precio}
                    onChange={(e) =>
                      handleInputChangePreciosUnitarios(
                        index,
                        "precio",
                        e.target.value
                      )
                    }
                  />
                </div>

                <div>
                  <label
                    htmlFor={"puntos"}
                    class="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Puntos
                  </label>
                  <input
                    type="number"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    placeholder="Puntos"
                    value={preciounitario.puntos}
                    onChange={(e) =>
                      handleInputChangePreciosUnitarios(
                        index,
                        "puntos",
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
              <div>
                <div className=" py-3">
                  <label
                    htmlFor={"descripcion"}
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Descripción
                  </label>
                  <textarea
                    id="descripcion"
                    rows={10}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={preciounitario.descripcion}
                    onChange={(e) =>
                      handleInputChangePreciosUnitarios(
                        index,
                        "descripcion",
                        e.target.value
                      )
                    }
                  ></textarea>
                </div>
              </div>

              <div>
                <InputText
                  value={preciounitario.linkReferencia}
                  title={"Link Referencia"}
                  setValue={(e) =>
                    handleInputChangePreciosUnitarios(
                      index,
                      "linkReferencia",
                      e
                    )
                  }
                />
              </div>

              <div className="">
                <FileUploadCliente
                  preciounitario={preciounitario}
                  index={index}
                  handleInputChangePreciosUnitarios={
                    handleInputChangePreciosUnitarios
                  }
                />
              </div>
              <div className="">
                <FileUploadEspecialista
                  preciounitario={preciounitario}
                  index={index}
                  handleInputChangePreciosUnitarios={
                    handleInputChangePreciosUnitarios
                  }
                />
              </div>
              <div className=" flex justify-center  py-2">
                <button
                  className=" flex justify-center w-full md:max-w-xs items-center bg-red-50 text-red-800 p-2 rounded-lg border border-red-400 hover:bg-red-100"
                  type="button"
                  onClick={() => handleDeletePreciounitario(index)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className=" mt-8 h-[1px] w-full bg-gray-200"></div>

      <div className=" flex justify-center py-6">
        <button
          type="button"
          onClick={() => {
            actualizarServicio()
            setFileToUpload(null)
          }}
          className=" w-full md:max-w-xs rounded-md font-semibold p-2 text-white bg-[#FF6400] hover:bg-orange-600 text-sm  "
        >
          Actualizar
        </button>
      </div>
    </DrawerRight>
  );
};

export default EditarServicioDrawer;

const FileUploadCliente = ({
  preciounitario,
  handleInputChangePreciosUnitarios,
  index,
}) => {
  const handleUpdateFile = async (index, e) => {
    handleInputChangePreciosUnitarios(
      index,
      "servicioProcesoClienteMediafiles",
      e
    );
    uploadImage(e[0]);
  };

  const uploadImage = async (fileInput) => {
    if (fileInput) {
      try {
        const formData = new FormData();
        formData.append("file", fileInput.source);
        const uploadResponse = await axios.post(
          `${BACKEND}/api/servicioProcesoClienteMedia`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        handleInputChangePreciosUnitarios(
          index,
          "servicioProcesoClienteMediaId",
          uploadResponse.data.doc.id
        );
      } catch (error) {}
    } else {
      handleInputChangePreciosUnitarios(
        index,
        "servicioProcesoClienteMediaId",
        ""
      );
    }
  };

  return (
    <FileUploadFilePond
      initialUrl={preciounitario.servicioProcesoClienteMediaInitialURL}
      handleUpdateFile={(e) => handleUpdateFile(index, e)}
      files={preciounitario.servicioProcesoClienteMediafiles}
      title="Proceso servicio cliente"
    />
  );
};

const FileUploadEspecialista = ({
  preciounitario,
  handleInputChangePreciosUnitarios,
  index,
}) => {
  const handleUpdateFile = async (index, e) => {
    handleInputChangePreciosUnitarios(
      index,
      "servicioProcesoEspecialistaMediaFiles",
      e
    );
    uploadImage(e[0]);
  };

  const uploadImage = async (fileInput) => {
    if (fileInput) {
      try {
        const formData = new FormData();
        formData.append("file", fileInput.source);
        const uploadResponse = await axios.post(
          `${BACKEND}/api/servicioProcesoEspecialistaMedia`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        handleInputChangePreciosUnitarios(
          index,
          "servicioProcesoEspecialistaMediaId",
          uploadResponse.data.doc.id
        );
      } catch (error) {}
    } else {
      handleInputChangePreciosUnitarios(
        index,
        "servicioProcesoEspecialistaMediaId",
        ""
      );
    }
  };

  return (
    <FileUploadFilePond
      initialUrl={preciounitario.servicioProcesoEspecialistaMediaInitialURL}
      handleUpdateFile={(e) => handleUpdateFile(index, e)}
      files={preciounitario.servicioProcesoEspecialistaMediaFiles}
      title="Proceso servicio especialista"
    />
  );
};
